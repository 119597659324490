import Headroom from "headroom.js";

(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  const header = document.querySelector('.main-header');
  const menuTrigger = document.querySelector('.menu-trigger--fake');
  const fullWidthPlayer = document.querySelector('.intro-video__item');

  let mobile = null;

  function handleDOMContentLoaded() {
    const headroom  = new Headroom(header, {
      offset: {
        up: 50,
        down: 50
      },
      onUnpin: () => menuTrigger.classList.add('menu-trigger--with-header'),
      onPin: () => menuTrigger.classList.remove('menu-trigger--with-header')
    });
    headroom.init();

    mobile = window.matchMedia('(max-width: 767px)');
    handleFullWidthVideo(mobile);
    mobile.addListener(handleFullWidthVideo);
  }

  function handleFullWidthVideo(e) {
    const videoPath = fullWidthPlayer.dataset.video;
    const videoMobilePath = fullWidthPlayer.dataset.videoMobile;
    fullWidthPlayer.setAttribute('src', e.matches ? videoMobilePath : videoPath);
  }

})();
